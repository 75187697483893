html {
  height: 100%;
  box-sizing: border-box;
  background-color: transparent;
  margin: 0;
  padding: 0;
  font-size: 10px;
}

body {
  box-sizing: border-box;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  margin: 0;
  min-height: 100%;
  font-family: "Open Sans";
  background-color: #ffffff;
  color: #171717;
  font-size: 1.4rem;
}

#root {
  min-height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  outline: none !important;
}
*:focus,
*::before:focus,
*::after:focus {
  outline: none !important;
}

.spacing {
  width: 100%;
  height: 1.6rem;
}

.no-data {
  padding: 1.6rem;
  width: 100%;
  display: block;
  text-align: center;
  background-color: #ffffff;
  color: #6a6a6a;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  left: 0;
  right: 0;
  padding: 0.8rem;
  background-color: #4c4c4c;
  -webkit-transition: left 0.3s linear;
  -moz-transition: left 0.3s linear;
  -ms-transition: left 0.3s linear;
  -o-transition: left 0.3s linear;
  transition: left 0.3s linear;
}

a {
  text-decoration: unset;
  color: unset;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #757575;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: #525252;
}

.new-done-orders {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10%;
  flex-wrap: wrap;
  position: relative;
}
.new-done-orders .top-container {
  position: absolute;
  width: 100%;
  background-color: #4cb576;
  top: 0;
}
.new-done-orders .top-container__text {
  color: white;
  font-size: 10rem;
  font-weight: 600;
  text-align: center;
}
.new-done-orders .text {
  width: 31%;
  color: #4cb576;
  font-size: 12vh;
  font-weight: 600;
  text-align: center;
  border: 5px solid lightgrey;
  margin-right: 1rem;
}

.tv {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.tv__onemin {
  border-bottom: 60px solid;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom-color: #ffffffcc;
  position: fixed;
  left: 50%;
  bottom: -50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 300px;
}
.tv__onemin__img {
  margin-left: 25px;
  margin-bottom: -50px;
  width: 200px;
}
.tv__container {
  width: 50%;
  height: 100%;
  border-right: 2px solid #e7ecef;
  overflow: hidden;
}
.tv__container--no-border {
  border-right: 0;
}
.tv__container__title-container {
  width: 50%;
  margin: auto;
  border-top: 80px solid;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
}
.tv__container__title-container--in-progress {
  border-top-color: #ffd750;
  color: #171717;
}
.tv__container__title-container--done {
  border-top-color: #4cb576;
  color: #ffffff;
}
.tv__container__title {
  margin-top: -65px;
  text-align: center;
  font-weight: 600;
  font-size: 3rem;
}
.tv__element-container {
  padding-top: 2.4rem;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}
.tv__element {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tv__element__text {
  min-width: 80%;
  width: 80%;
  font-size: 10vh;
  font-weight: 600;
  text-align: center;
  background-color: #ffffff66;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  box-shadow: 5px 5px 25px 5px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);
}

.no-id {
  width: 100%;
  height: 100vh;
  background-color: white;
}
.no-id__onemin {
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
}
.no-id__onemin__img {
  margin: auto;
  display: block;
  width: 25%;
}
.no-id__text-box {
  width: 100%;
  background-color: #f5f5f5;
  padding-top: 5%;
  padding-bottom: 5%;
}
.no-id__text-box__error-text {
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}
.no-id__text-box__link-text {
  font-size: 3.5rem;
  font-weight: 600;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.no-id__text-box__link-text--red {
  color: #ec3753;
}
.no-id__text-box__instruction-text {
  background-color: white;
  font-size: 3.5rem;
  font-weight: 600;
  text-align: center;
  padding: 1rem 4rem;
  width: fit-content;
  display: block;
  margin: 3% auto;
}

.fancy-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.fancy-loader-container .loader {
  -webkit-animation: rotate 1s infinite;
  -moz-animation: rotate 1s infinite;
  -ms-animation: rotate 1s infinite;
  -o-animation: rotate 1s infinite;
  animation: rotate 1s infinite;
  height: 25px;
  width: 25px;
  margin-bottom: 1.2rem;
}
.fancy-loader-container .loader:before,
.fancy-loader-container .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 10px;
  width: 10px;
}
.fancy-loader-container .loader:before {
  -webkit-animation: ball1 1s infinite;
  -moz-animation: ball1 1s infinite;
  -ms-animation: ball1 1s infinite;
  -o-animation: ball1 1s infinite;
  animation: ball1 1s infinite;
  background-color: #fb3350;
  box-shadow: 15px 0 0 #a93e73;
  margin-bottom: 5px;
}
.fancy-loader-container .loader:after {
  -webkit-animation: ball2 1s infinite;
  -moz-animation: ball2 1s infinite;
  -ms-animation: ball2 1s infinite;
  -o-animation: ball2 1s infinite;
  animation: ball2 1s infinite;
  background-color: #a93e73;
  box-shadow: 15px 0 0 #fb3350;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@keyframes ball1 {
  0% {
    box-shadow: 15px 0 0 #a93e73;
  }
  50% {
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    box-shadow: 15px 0 0 #a93e73;
    margin-bottom: 5px;
  }
}
@keyframes ball2 {
  0% {
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    box-shadow: 0 0 0 #fb3350;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    box-shadow: 15px 0 0 #fb3350;
    margin-top: 0;
  }
}
