@font-face {
    font-family: "Open Sans";
    src: url("./Open_Sans/OpenSans-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("./Open_Sans/OpenSans-Bold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: url("./Open_Sans/OpenSans-BoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 600;
}
@font-face {
    font-family: "Open Sans";
    src: url("./Open_Sans/OpenSans-ExtraBold.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: url("./Open_Sans/OpenSans-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: "Open Sans";
    src: url("./Open_Sans/OpenSans-Italic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
}
@font-face {
    font-family: "Open Sans";
    src: url("./Open_Sans/OpenSans-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: "Open Sans";
    src: url("./Open_Sans/OpenSans-LightItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 300;
}
@font-face {
    font-family: "Open Sans";
    src: url("./Open_Sans/OpenSans-SemiBold.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: url("./Open_Sans/OpenSans-SemiBoldItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}
